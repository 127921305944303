import { format } from "date-fns";
import { concat, filter, fromPromise, map, pipe } from "wonka";
import { Override } from "../types/index";
import { dateToStr, strToDate } from "../utils/dates";
import { deserialize, upsert } from "../utils/wonka";
import { DayOfWeek, Weekdays } from "./Calendars";
import {
  AssistType,
  DailyHabit as HabitDto,
  DayOfWeek as DayOfWeekDto,
  Recurrence as RecurrenceDto,
  RecurringAssignmentStatus,
  RecurringAssignmentType,
  Smurf as SmurfDto,
  SubscriptionType,
  ThinPerson as ThinPersonDto,
} from "./client";
import { Category, EventColor, PrimaryCategory } from "./EventMetaTypes";
import { Recurrence } from "./OneOnOnes";
import { Smurf } from "./Projects";
import { NotificationKeyStatus, TransformDomain } from "./types";
import { User } from "./Users";

// WARNING BEFORE EDITING: These values are copied from the server, this is what is set by the server when an undefined value is set
// Be careful changing these to make sure they are in sync with the server in /src/main/java/ai/reclaim/server/assist/TaskOrHabit.java
// TODO: Find a way to add this to OpenAPI (ma)
export const DefaultAutoDeclineText =
  "Hi! This is Reclaim, {name}'s virtual assistant. I'm sorry, but {name} has a commitment at this time, and it's one of the last open slots available to get it done. Can you find another time to meet?";
export const DefaultDefendedDescription =
  "Reclaim has blocked this time off for {name} to work on an important commitment. Reclaim defended this time because it's one of the last available in {name}'s schedule. Please find another time to meet with {name}.";

export type HabitType = Override<
  HabitDto,
  {
    readonly effectivePriority?: Smurf;
    readonly updated?: Date;

    readonly deleted?: boolean;

    index: number;
    enabled: boolean;
    title: string;
    eventCategory: PrimaryCategory;
    eventColor?: EventColor;
    daysActive?: DayOfWeek[];
    idealDay?: DayOfWeek | null;
    recurrence: Recurrence | null;
    priority?: Smurf;
    priorityUntil?: Date;
    snoozeUntil?: Date | null;
  }
>;

export class Habit implements HabitType {
  readonly id: number;
  readonly effectivePriority?: Smurf;
  readonly updated?: Date;

  readonly deleted?: boolean;

  title: string;
  index: number;
  enabled: boolean;
  eventCategory: PrimaryCategory;

  campaign?: string;
  defendedDescription: string;
  privateDescription: string;
  additionalDescription: string;
  notification: boolean;
  windowStart: string;
  windowEnd: string;
  idealTime: string;
  idealDay: DayOfWeek;
  durationMin: number;
  durationMax: number;
  timesPerPeriod?: number;
  daysActive?: DayOfWeek[];
  recurrence: Recurrence | null;
  invitees: ThinPersonDto[];
  alwaysPrivate: boolean;
  autoDecline: boolean;
  autoDeclineText: string;
  defenseAggression: number;
  elevated: boolean;
  reservedWords: string[];
  eventColor?: EventColor;

  priority?: Smurf;
  priorityUntil?: Date;
  snoozeUntil?: Date | null;

  type?: AssistType | undefined;
  created: string;
  recurringAssignmentType: RecurringAssignmentType;
  rrule: string;
  timePolicy: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  conferenceType: any;
  conferenceData: object | null;
  location?: string | null | undefined;
  status?: RecurringAssignmentStatus | undefined;
  eventFilter?: object | undefined;
  prioritizableType?: string | undefined;

  // TODO (IW): Find a better place for this (and Task.getColor, Calendar.getColor)
  static getColor(user: User, habit: Habit): EventColor | undefined {
    return !!habit.eventColor && habit.eventColor?.key !== EventColor.Auto.key
      ? habit.eventColor
      : EventColor.getColor(user, habit.eventCategory);
  }
}

export function dtoToHabit(dto: HabitDto): Habit {
  return {
    ...dto,
    id: !!dto.id ? (dto.id as number) : undefined, // strip 0 ids (long id == 0)
    eventCategory: !!dto.eventCategory ? Category.get(dto.eventCategory as unknown as string) : undefined,
    eventColor: !!dto.eventColor ? EventColor.get(dto.eventColor) : EventColor.Auto,
    recurrence: !!dto.recurrence ? Recurrence.get(dto.recurrence) : null,
    daysActive: dto.daysActive as unknown as DayOfWeek[],
    idealDay: dto.idealDay as unknown as DayOfWeek,
    priorityUntil: strToDate(dto.priorityUntil, false),
    snoozeUntil: dto.snoozeUntil === null ? null : strToDate(dto.snoozeUntil, false),
    timesPerPeriod: dto.timesPerPeriod,
    updated: strToDate(dto.updated, false),
    priority: dto.priority as unknown as Smurf,
  } as Habit; // TODO (IW) Ditch casting once swagger required/optional fields are configured properly
}

export function habitToDto(habit: Partial<Habit>): Partial<HabitDto> {
  const data: Partial<HabitDto> = {
    ...habit,
    eventCategory: habit.eventCategory?.toJSON() as HabitDto["eventCategory"],
    eventColor: (EventColor.Auto === habit.eventColor ? null : habit.eventColor?.toJSON()) as HabitDto["eventColor"],
    recurrence: (habit.recurrence?.key as RecurrenceDto) || null,
    daysActive: habit.daysActive as unknown as DayOfWeekDto[],
    idealDay: habit.idealDay as unknown as DayOfWeekDto,
    priority: habit.priority as unknown as SmurfDto,
    // TODO ask Patrick why? we really need to stick to a format (ma)
    priorityUntil: habit.priorityUntil ? format(habit.priorityUntil, "yyyy-MM-dd") : undefined,
    snoozeUntil: habit.snoozeUntil === null ? null : dateToStr(habit.snoozeUntil),
    timesPerPeriod: habit.timesPerPeriod,
    updated: habit.updated?.toISOString(),
  };

  return data;
}

const DailyHabitSubscription = {
  subscriptionType: SubscriptionType.DailyHabit,
};

export class HabitsDomain extends TransformDomain<Habit, HabitDto> {
  resource = "Habit";
  cacheKey = "habits";
  pk = "id";

  public serialize = habitToDto;
  public deserialize = dtoToHabit;

  watchAll$ = pipe(
    this.ws.subscription$$(DailyHabitSubscription),
    filter((envelope) => !!envelope.data),
    map((envelope) => envelope.data),
    deserialize(this.deserialize),
    map((items) => this.patchExpectedChanges(items))
  );

  list$$ = () =>
    pipe(
      fromPromise(this.list()),
      map((items) => this.patchExpectedChanges(items))
    );

  listAndWatch$$ = () => {
    return pipe(
      concat<Habit[] | null>([this.list$$(), this.watchAll$]),
      upsert((h) => this.getPk(h)),
      map((items) => [...items])
    );
  };

  list = this.deserializeResponse(this.api.assist.getDailyHabits);

  get = this.deserializeResponse(this.api.assist.getDailyHabit);

  create = this.deserializeResponse((habit: Habit) => {
    const notificationKey = this.generateUid("create");

    this.addNotificationKey(notificationKey, NotificationKeyStatus.Pending, true);

    return this.api.assist
      .create(this.serialize(habit) as HabitDto, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  });

  patch = this.deserializeResponse((habit: Partial<Habit>) => {
    // exclude additionalDescription when updating until that feature has a field in the UI
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, additionalDescription, ...rest } = habit;
    const notificationKey = this.generateUid("patch", habit);

    this.expectChange(notificationKey, id!, rest, true);

    return this.api.assist
      .patch(id!, this.serialize(rest) as HabitDto, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  });

  // Note, this reIndex method exists as a one off for lack of time to create a better genaric abstraction to patch that accounts for the expected server
  // result of non-directly interacted changes. Maybe this is OK if we dont need to do this much? W.E.T. principles applied here.
  reIndex = this.deserializeResponse(
    (vars: { habitId: number; habits: Array<Partial<Habit> | { id: number; index: number }> }) => {
      const habit = vars.habits.find((h) => h.id === vars.habitId);
      if (!habit) throw new Error("Cant find chengedHabit to reindex");
      const notificationKey = this.generateUid("patch", habit);

      // Here we need to wait for the knock on changes resulting from this index change
      vars.habits.forEach(({ id, ...rest }) => !!id && this.expectChange(notificationKey, id, rest));

      this.addNotificationKey(notificationKey, NotificationKeyStatus.Pending, true);

      return this.api.assist
        .patch(vars.habitId!, this.serialize(habit) as HabitDto, { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    }
  );

  delete = (id: number) => {
    const notificationKey = this.generateUid("delete", id);

    this.expectChange(notificationKey, id, { deleted: true });

    return this.api.assist
      .delete1(id, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };

  // FIXME (IW): These are all event actions, not specific to habits at all.
  // Should these be refactored on the backend?

  markDone = (calendarId: number, eventId: string) => {
    const notificationKey = this.generateUid("markDone", `${calendarId}/${eventId}`);

    this.addNotificationKey(notificationKey);

    return this.api.habits
      .done(calendarId, eventId, {}, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };

  restart = (calendarId: number, eventId: string) => {
    const notificationKey = this.generateUid("restart", `${calendarId}/${eventId}`);

    this.addNotificationKey(notificationKey);

    return this.api.habits
      .restart(calendarId, eventId, {}, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };

  start = (calendarId: number, eventId: string) => {
    const notificationKey = this.generateUid("start", `${calendarId}/${eventId}`);

    this.addNotificationKey(notificationKey);

    return this.api.habits
      .start(calendarId, eventId, {}, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };

  startNow = (id: number) => {
    const notificationKey = this.generateUid("startNow", id);

    this.addNotificationKey(notificationKey);

    return this.api.habits
      .startNow(id, {}, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };

  autoReschedule = (calendarId: number, eventId: string) => {
    const notificationKey = this.generateUid("autoReschedule", `${calendarId}/${eventId}`);

    this.addNotificationKey(notificationKey);

    return this.api.habits
      .autoReschedule(calendarId, eventId, {}, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };

  skip = (calendarId: number, eventId: string) => {
    const notificationKey = this.generateUid("skip", `${calendarId}/${eventId}`);

    this.addNotificationKey(notificationKey);

    return this.api.habits
      .skip(calendarId, eventId, {}, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };

  snooze = (calendarId: number, eventId: string, chunks: number) => {
    const notificationKey = this.generateUid("snooze", `${calendarId}/${eventId}`);

    this.addNotificationKey(notificationKey, NotificationKeyStatus.Pending, true);

    return this.api.habits
      .snooze(
        calendarId,
        eventId,
        {},
        {
          timeChunks: chunks,
          notificationKey,
        },
        {}
      )
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };

  extend = (calendarId: number, eventId: string, chunks: number) => {
    const notificationKey = this.generateUid("extend", `${calendarId}/${eventId}`);

    this.addNotificationKey(notificationKey);

    return this.api.habits
      .extend(
        calendarId,
        eventId,
        {},
        {
          timeChunks: chunks,
          notificationKey,
        },
        {}
      )
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  };

  createDefaults = (data: { lunch: boolean; catchup: boolean }) => {
    // TODO createDefaultHabits types are not cast on the server / OpenAPI (ma)
    return this.api.assist.createDefaultHabits(data);
  };
}

export const defaultHabit: Partial<Habit> = {
  title: "",
  daysActive: Weekdays,
  eventCategory: PrimaryCategory.Personal,
  eventColor: EventColor.Auto,
  enabled: true,
  defenseAggression: 0,
  autoDecline: false,
  durationMin: 15,
  durationMax: 120,
  windowStart: "08:00:00",
  windowEnd: "18:00:00",
  idealTime: "09:00:00",
  recurrence: Recurrence.Weekly,
  alwaysPrivate: false,
  invitees: [],
  reservedWords: [],
  notification: true,
  index: 0,
  priority: Smurf.Default,
};

export class PresetHabit {
  static WeeklyStatus = new PresetHabit({
    title: "✍️ Weekly Status",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "15:00:00",
    windowEnd: "18:00:00",
    idealTime: "16:00:00",
    durationMin: 30,
    durationMax: 60,
    timesPerPeriod: 1,
    idealDay: DayOfWeek.Friday,
  });
  static Meditation = new PresetHabit({
    title: "☯️ Meditation",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "10:00:00",
    idealTime: "08:00:00",
    durationMin: 15,
    durationMax: 30,
  });
  static Exercise = new PresetHabit({
    title: "💪 Exercise",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "18:00:00",
    idealTime: "08:30:00",
    durationMin: 30,
    durationMax: 60,
  });
  static Coding = new PresetHabit({
    title: "💻 Coding",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "18:00:00",
    idealTime: "10:00:00",
    durationMin: 60,
    durationMax: 120,
  });
  static Networking = new PresetHabit({
    title: "🤝 Networking",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Saturday, DayOfWeek.Sunday],
    windowStart: "10:00:00",
    windowEnd: "17:00:00",
    idealTime: "11:00:00",
    durationMin: 30,
    durationMax: 60,
  });
  static Reading = new PresetHabit({
    title: "📖 Reading",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "18:00:00",
    windowEnd: "21:00:00",
    idealTime: "09:00:00",
    durationMin: 30,
    durationMax: 120,
  });
  static TakeWalk = new PresetHabit({
    title: "🌳 Take a Walk",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "12:00:00",
    windowEnd: "14:00:00",
    idealTime: "13:00:00",
    durationMin: 15,
    durationMax: 30,
  });
  static Writing = new PresetHabit({
    title: "✏️ Writing",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "18:00:00",
    windowEnd: "21:00:00",
    idealTime: "19:00:00",
    durationMin: 30,
    durationMax: 120,
  });
  static CatchUp = new PresetHabit({
    title: "📨 Catch Up",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "10:00:00",
    idealTime: "09:00:00",
    durationMin: 15,
    durationMax: 30,
  });
  static Lunch = new PresetHabit({
    title: "🍱 Lunch",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "11:30:00",
    windowEnd: "14:00:00",
    idealTime: "12:00:00",
    durationMin: 30,
    durationMax: 60,
    reservedWords: [
      "lunch",
      "almuerzo",
      "mittagessen",
      "déjeuner",
      "pranzo",
      "dinar",
      "午餐",
      "ランチ",
      "점심",
      "frokost",
      "obiad",
      "almoço",
    ],
  });
  static BizOps = new PresetHabit({
    title: "💸 BizOps Review",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Friday],
    windowStart: "15:00:00",
    windowEnd: "18:00:00",
    idealTime: "16:00:00",
    durationMin: 30,
    durationMax: 60,
  });
  static CustomerSupport = new PresetHabit({
    title: "💬 Customer Support",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "10:00:00",
    idealTime: "09:00:00",
    durationMin: 30,
    durationMax: 60,
  });

  static MetricsReview = new PresetHabit({
    title: "📈 Metrics Review",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "17:00:00",
    idealTime: "09:00:00",
    durationMin: 60,
    durationMax: 120,
    recurrence: Recurrence.Monthly,
  });

  static BudgetReview = new PresetHabit({
    title: "💸 Budget Review",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "17:00:00",
    idealTime: "09:00:00",
    durationMin: 180,
    durationMax: 240,
    recurrence: Recurrence.Quarterly,
  });

  static SprintPlanning = new PresetHabit({
    title: "🏃 Sprint Planning",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "17:00:00",
    idealTime: "09:00:00",
    durationMin: 60,
    durationMax: 120,
    recurrence: Recurrence.Biweekly,
  });

  static MonthlyReport = new PresetHabit({
    title: "✍️ Monthly Report",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday],
    windowStart: "08:00:00",
    windowEnd: "17:00:00",
    idealTime: "09:00:00",
    durationMin: 120,
    durationMax: 180,
    recurrence: Recurrence.Monthly,
  });

  static HouseCleaning = new PresetHabit({
    title: "🏡 Housecleaning",
    eventCategory: PrimaryCategory.Personal,
    daysActive: [DayOfWeek.Saturday, DayOfWeek.Sunday],
    windowStart: "08:00:00",
    windowEnd: "17:00:00",
    idealTime: "08:00:00",
    durationMin: 360,
    durationMax: 480,
    recurrence: Recurrence.Quarterly,
  });

  static FocusTime = new PresetHabit({
    title: "🧑‍💻 Focus Time",
    eventCategory: PrimaryCategory.SoloWork,
    daysActive: [
      DayOfWeek.Monday,
      DayOfWeek.Tuesday,
      DayOfWeek.Wednesday,
      DayOfWeek.Thursday,
      DayOfWeek.Friday,
      DayOfWeek.Saturday,
      DayOfWeek.Sunday,
    ],
    windowStart: "08:00:00",
    windowEnd: "17:00:00",
    idealTime: "08:00:00",
    durationMin: 120,
    durationMax: 240,
    timesPerPeriod: 0,
    recurrence: null,
  });

  // **** social issue habits ****

  // **** end social issue habits ****

  static isPresetHabit(title: string): boolean {
    const titles = Object.keys(PresetHabit)
      .filter((k) => !!PresetHabit[k]["title"])
      .map((k) => PresetHabit[k]["title"]);
    return titles.includes(title.trim());
  }

  constructor(public readonly data: Partial<Habit>) {}

  toJSON() {
    return this.data;
  }
}

export function userDefaultHabit(user?: User | null, overrides: Partial<Habit> = {}): Partial<Habit> {
  // additionalDescription gets used in google cal, and that requires no line breaks or extra spaces, so ensure its clean here
  if (overrides.additionalDescription) {
    overrides.additionalDescription = overrides.additionalDescription
      .replace(/(\r\n|\n|\r)/gm, "")
      .replace(/[\t ]+\</g, "<")
      .replace(/\>[\t ]+\</g, "><")
      .replace(/\>[\t ]+$/g, ">");
  }

  return { ...defaultHabit, ...overrides };
}
