import { addDays, addMinutes, setDay, setHours, setMinutes } from "date-fns";
import { concat, filter, fromPromise, map, pipe } from "wonka";
import { ObjectEnum } from "../types/enums";
import { Override } from "../types/index";
import { dateToStr, dayOfWeekToNumber, roundTimeToChunk, strToDate } from "../utils/dates";
import { deserialize, upsert } from "../utils/wonka";
import { DayOfWeek } from "./Calendars";
import {
  Priority,
  ProjectInclude,
  Reindex as ReindexDao,
  ReindexDirection as ReindexDirectionDao,
  Smurf as SmurfDto,
  SubscriptionType,
  Task as TaskDto,
  TaskDefaults as TaskDefaultsDto,
  TaskInstance as TaskInstanceDto,
  TaskStatus as TaskStatusDto,
} from "./client";
import { Category, EventColor, EventType, PrimaryCategory } from "./EventMetaTypes";
import { ThinPerson } from "./People";
import { dtoToProject, IncludeProject, Project, Smurf } from "./Projects";
import { NotificationKeyStatus, TransformDomain } from "./types";
import { User } from "./Users";

export { ProjectInclude } from "./client";

export class TaskStatus extends ObjectEnum {
  static New = new TaskStatus(TaskStatusDto.NEW, false, "Active");
  static Scheduled = new TaskStatus(TaskStatusDto.SCHEDULED, false, "Scheduled");
  static InProgress = new TaskStatus(TaskStatusDto.IN_PROGRESS, false, "In-progress");
  static Complete = new TaskStatus(TaskStatusDto.COMPLETE, true, "Complete");
  static Cancelled = new TaskStatus(TaskStatusDto.CANCELLED, true, "Cancelled");
  static Archived = new TaskStatus(TaskStatusDto.ARCHIVED, true, "Archived");

  static Active = [TaskStatus.Scheduled, TaskStatus.InProgress, TaskStatus.Complete];

  constructor(public readonly status: TaskStatusDto, public readonly done: boolean, public readonly label: string) {
    super(status);
  }
}

export enum ReindexDirection {
  Before = "before",
  After = "after",
}

export type Reindex = Override<
  ReindexDao,
  {
    relativeTaskId: number;
    reindexDirection: ReindexDirection;
  }
>;

export type TaskDefaults = Override<
  TaskDefaultsDto,
  {
    category: Category;
  }
>;

export enum TaskInstanceStatus {
  Done = "DONE",
  Active = "ACTIVE",
  Pending = "PENDING",
  Aborted = "ABORTED",
}

export type TaskInstance = Override<
  TaskInstanceDto,
  {
    start?: Date;
    end?: Date;
    status: TaskInstanceStatus;
  }
>;

export type Task = Override<
  TaskDto,
  {
    readonly id: number;
    readonly created?: Date;
    readonly updated?: Date;
    readonly finished?: Date | null;
    readonly projects?: Project[];
    readonly instances?: TaskInstance[];
    readonly effectivePriority?: Smurf;

    readonly deleted?: boolean;

    title: string;
    status?: TaskStatus;
    eventCategory: PrimaryCategory;
    due?: Date;

    snoozeUntil?: Date | null;
    eventColor?: EventColor;

    priority?: Smurf;

    invitees?: ThinPerson[];
  }
>;

/* Sorting functions */

export const byTitle = (a: Task, b: Task) => {
  if (a.title === b.title) return 0;
  return a.title < b.title ? -1 : 1;
};

export const byDue = (a: Task, b: Task) => {
  if (a.due?.getTime() === b.due?.getTime()) return byTitle(a, b);
  if (!a.due) return 1;
  if (!b.due) return -1;
  return a.due.getTime() - b.due.getTime();
};

export const byFinished = (a: Task, b: Task) => {
  if (a.finished?.getTime() === b.finished?.getTime()) return byDue(a, b);
  if (!a.finished) return 1;
  if (!b.finished) return -1;
  return a.finished.getTime() - b.finished.getTime();
};

export const byScheduled = (a: Task, b: Task) => {
  const nextChunkA = a?.instances?.[0]?.start?.getTime() || Infinity;
  const nextChunkB = b?.instances?.[0]?.start?.getTime() || Infinity;

  if (nextChunkA === nextChunkB) return byFinished(a, b);
  return nextChunkA - nextChunkB;
};

export const byTimeChunksRequired = (a: Task, b: Task) => {
  if (a.timeChunksRequired === b.timeChunksRequired) return byDue(a, b);
  if (undefined === a.timeChunksRequired) return 1;
  if (undefined === b.timeChunksRequired) return -1;
  return a.timeChunksRequired - b.timeChunksRequired;
};

export const byStatus = (a: Task, b: Task) => {
  if (a.status === b.status) return byScheduled(a, b);
  if (!a.status) return 1;
  if (!b.status) return -1;
  return a.status.key < b.status.key ? -1 : 1;
};

export const byTimeChunksRemaining = (a: Task, b: Task) => {
  if (a.timeChunksRemaining === b.timeChunksRemaining) return byScheduled(a, b);
  if (undefined === a.timeChunksRemaining) return 1;
  if (undefined === b.timeChunksRemaining) return -1;
  return a.timeChunksRemaining - b.timeChunksRemaining;
};

export function getTaskColor(user: User, task: Task): EventColor | undefined {
  return !!task.eventColor && task.eventColor !== EventColor.Auto
    ? task.eventColor
    : EventColor.getColor(user, task.eventCategory);
}

export type RequestQuery = {
  status?: TaskStatus[] | null | null;
  project?: number | null;
  priority?: Priority;
  includeProjects?: IncludeProject;
};

export function dtoToTask(dto: TaskDto): Task {
  return {
    ...dto,
    id: dto.id as number,
    title: dto.title || "",
    priority: dto.priority as unknown as Smurf,
    status: !!dto.status ? TaskStatus.get(dto.status) : undefined,
    eventCategory: !!dto.eventCategory
      ? PrimaryCategory.get(dto.eventCategory as unknown as string)
      : PrimaryCategory.TeamMeeting,
    eventColor: !!dto.eventColor ? EventColor.get(dto.eventColor) : EventColor.Auto,
    due: strToDate(dto.due, false),
    snoozeUntil: dto.snoozeUntil === null ? null : strToDate(dto.snoozeUntil, false),
    finished: dto.finished === null ? null : strToDate(dto.finished, false),
    created: strToDate(dto.created, false),
    updated: strToDate(dto.updated, false),
    projects: dto.projects?.map(dtoToProject),
    instances: dto.instances
      ?.map((i) => ({
        ...i,
        start: strToDate(i.start, false),
        end: strToDate(i.end, false),
        status: i.status as unknown as TaskInstanceStatus,
      }))
      .filter((i) => i.status !== TaskInstanceStatus.Aborted)
      .sort((a, b) => (!a.start || !b.start ? +1 : a.start.getTime() - b.start.getTime())),
  };
}

export function taskToDto(task: Partial<Task>): Partial<TaskDto> {
  return {
    ...task,
    priority: task.priority as unknown as TaskDto["priority"],
    status: task.status?.toJSON() as TaskDto["status"],
    eventCategory: task.eventCategory?.toJSON() as TaskDto["eventCategory"],
    eventColor: (EventColor.Auto === task.eventColor ? null : task.eventColor?.toJSON()) as TaskDto["eventColor"],
    due: dateToStr(task.due),
    snoozeUntil: task.snoozeUntil === null ? null : dateToStr(task.snoozeUntil),
    finished: task.finished === null ? null : dateToStr(task.finished),
    created: dateToStr(task.created),
    updated: dateToStr(task.updated),

    // we dont augment this, no error, but why send so much back?
    projects: undefined,
    // TODO (ma) backend does not deal with this fully yet.
    // data is not yet usable by UI and re-sending brakes the server
    // @ts-ignore
    googleTask: undefined,
    instances: undefined,
  };
}

const TaskSubscription = {
  subscriptionType: SubscriptionType.Task,
};

export class TasksDomain extends TransformDomain<Task, TaskDto> {
  resource = "Task";
  cacheKey = "tasks";
  pk = "id";

  public serialize = taskToDto;
  public deserialize = dtoToTask;

  watchAll$ = pipe(
    this.ws.subscription$$(TaskSubscription),
    filter((envelope) => !!envelope.data),
    map((envelope) => envelope.data),
    deserialize(this.deserialize),
    map((items) => this.patchExpectedChanges(items))
  );

  list$$ = (query?: {
    status?: TaskStatus[] | null;
    project?: number | null;
    priority?: Smurf;
    id?: number[] | null;
    instances?: boolean | null;
    includeProjects?: ProjectInclude;
  }) => {
    return pipe(
      fromPromise(
        this.list(
          query as
            | {
                status?: TaskStatusDto[] | null | null;
                project?: number | null;
                priority?: SmurfDto;
                id?: number[] | null | null;
                instances?: boolean | null;
                includeProjects?: ProjectInclude;
              }
            | undefined
        )
      ),
      map((items) => this.patchExpectedChanges(items))
    );
  };

  listAndWatch$$ = (query?: {
    status?: TaskStatus[] | null;
    project?: number | null;
    priority?: Smurf;
    id?: number[] | null;
    instances?: boolean | null;
    includeProjects?: ProjectInclude;
  }) => {
    return pipe(
      concat<Task[] | null>([this.list$$(query), this.watchAll$]),
      upsert((e) => this.getPk(e)),
      map((items: Task[]) => {
        return items
          .filter((i) => !query?.id || query.id.includes(i.id))
          .filter((i) => !query?.status || (!!i.status && query.status.includes(i.status)))
          .filter((i) => !query?.priority || query.priority === i.priority);
      }),
      map((items) => [...items])
    );
  };

  watchId$$ = (id: number, instances?: boolean) => {
    return pipe(
      this.listAndWatch$$({ id: [id], instances }),
      map((items) => items?.find((i) => i.id === id))
    );
  };

  list = this.manageErrors(this.deserializeResponse(this.api.tasks.query3));

  get = this.manageErrors(
    this.deserializeResponse((id: number, instances?: boolean, includeProjects?: boolean) =>
      this.api.tasks.getTask(id, { instances, includeProjects: !!includeProjects ? ProjectInclude.ID : undefined })
    )
  );

  create = this.manageErrors(
    this.deserializeResponse((task: Partial<Omit<Task, "id">>) => {
      const notificationKey = this.generateUid("create");

      this.addNotificationKey(notificationKey, NotificationKeyStatus.Pending, true);

      return this.api.tasks
        .create2(this.serialize(task) as TaskDto, { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  update = this.manageErrors(
    this.deserializeResponse((task: Partial<Task> & { id: number }) => {
      const notificationKey = this.generateUid("update", task.id);

      this.addNotificationKey(notificationKey, NotificationKeyStatus.Pending, true);

      return this.api.tasks
        .put2(task.id, this.serialize(task) as TaskDto, { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  patch = this.manageErrors(
    this.deserializeResponse(async (id: number, patch: Partial<Task>) => {
      const notificationKey = this.generateUid("patch", id);

      this.expectChange(notificationKey, id, patch, true);

      return this.api.tasks
        .patch3(id, this.serialize(patch), { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  updateInstance = this.manageErrors(
    (task: Partial<Task> & { id: number }, index: number, eTag: string, status: TaskInstanceStatus) => {
      const notificationKey = this.generateUid("updateInstance", `${task.id}/${index}`);

      this.addNotificationKey(notificationKey);

      return this.api.tasks
        .updateInstance(
          task.id as number,
          index,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          { status } as any, // FIXME (IW): Not typed properly by swagger
          { notificationKey },
          {
            headers: {
              "If-Match": eTag,
            },
          }
        )
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    }
  );

  delete = this.manageErrors((task: Partial<Task> & { id: number }) => {
    const notificationKey = this.generateUid("delete", task.id);

    this.expectChange(notificationKey, task.id, { status: TaskStatus.Complete }, true);

    return this.api.tasks
      .delete5(task.id, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  });

  start = this.manageErrors(
    this.deserializeResponse((task: Task) => {
      const notificationKey = this.generateUid("start", task.id);
      const expectChange = task.instances?.findIndex((i) => i.status === TaskInstanceStatus.Active) !== -1;

      if (expectChange) this.expectChange(notificationKey, task.id, { status: TaskStatus.InProgress });
      else this.addNotificationKey(notificationKey);

      // TODO data param should be optional (ma)
      return this.api.tasks
        .start1(task.id as number, {}, { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          if (expectChange) this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          else this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  stop = this.manageErrors(
    this.deserializeResponse((task: Task) => {
      const notificationKey = this.generateUid("stop", task.id);

      this.expectChange(notificationKey, task.id, { status: TaskStatus.Scheduled });

      return this.api.tasks
        .stop(task.id, {}, { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  cancel = this.manageErrors(
    this.deserializeResponse((task: Task) => {
      const notificationKey = this.generateUid("cancel", task.id);

      this.expectChange(notificationKey, task.id, { status: TaskStatus.Complete });

      return this.api.tasks
        .cancel1(task.id, {}, { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  finish = this.manageErrors(
    this.deserializeResponse((task: Task | number) => {
      const pk = typeof task === "number" ? task : task.id;
      const notificationKey = this.generateUid("finish", pk);

      this.expectChange(notificationKey, pk, { status: TaskStatus.Complete });

      return this.api.tasks
        .done1(pk, {}, { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  archive = this.manageErrors(
    this.deserializeResponse((task: Task) => {
      const notificationKey = this.generateUid("archive", task.id);

      this.addNotificationKey(notificationKey);

      return this.api.tasks
        .archive(task.id, {}, { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  unarchive = this.manageErrors(
    this.deserializeResponse((task: Task) => {
      const notificationKey = this.generateUid("unarchive", task.id);

      this.addNotificationKey(notificationKey, NotificationKeyStatus.Pending, true);

      return this.api.tasks
        .unarchive(task.id, {}, { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  extend = this.manageErrors(
    this.deserializeResponse((change: { task: Partial<Task> & { id: number }; timeChunks: number }) => {
      const notificationKey = this.generateUid("extend", change.task.id);
      const expectChange = !!change.task.timeChunksRequired && change.task.timeChunksRequired > 0;

      if (expectChange)
        this.expectChange(
          notificationKey,
          change.task.id,
          {
            timeChunksRequired: change.task.timeChunksRequired! + change.timeChunks,
          },
          true
        );
      else this.addNotificationKey(notificationKey);

      return this.api.tasks
        .extend1(change.task.id as number, {}, { timeChunks: change.timeChunks, notificationKey }, {})
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          if (expectChange) this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          else this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  // FIXME (IW): Replace w/ optimistic stuff
  injectResult = (result: TaskDto): TaskDto => {
    // Find active subscriptions and pipe data through.
    this.ws.subscriptions.forEach((value, key) => {
      if (key.startsWith(`${SubscriptionType.Task}_`)) {
        this.ws.pushMessage(result, SubscriptionType.Task, key);
      }
    });

    return result;
  };

  reindex = this.manageErrors(
    this.deserializeResponse((task: Task, relativeId: number, direction: ReindexDirection) => {
      const notificationKey = this.generateUid("reindex", task);
      const payload: ReindexDao = {
        relativeTaskId: relativeId,
        reindexDirection: direction as unknown as ReindexDirectionDao,
      };

      return this.api.tasks
        .reindex(task.id, payload)
        .then(this.injectResult)
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          throw reason;
        });
    })
  );
}

export const defaultTask: Partial<Task> = {
  title: "",
  status: TaskStatus.New,
  timeChunksRequired: 4,
  due: addDays(setMinutes(setHours(new Date(), 20), 0), 3),
  eventCategory: PrimaryCategory.SoloWork,
  eventColor: EventColor.Auto,
  projectIds: [],
  minChunkSize: 4,
  maxChunkSize: 32,
  alwaysPrivate: false,
};

export function makeDefaultTask(user?: User | null, forWeekStarting?: Date): Task {
  let dateBasedOnPlanner: Date | undefined = undefined;

  if (forWeekStarting) {
    dateBasedOnPlanner = roundTimeToChunk(
      setHours(
        setDay(
          forWeekStarting,
          dayOfWeekToNumber(
            (user?.features.timePolicies?.work?.startOfWeek as unknown as DayOfWeek) || DayOfWeek.Sunday
          )
        ),
        8 // 8:00 am
      )
    );
  }
  if (!user?.features?.taskSettings?.defaults) {
    return {
      ...defaultTask,
    } as Task;
  }

  const defaults = user.features.taskSettings.defaults;
  const snoozeUntil: Date | null =
    !!dateBasedOnPlanner && dateBasedOnPlanner.getTime() > (defaultTask.snoozeUntil || new Date())?.getTime()
      ? dateBasedOnPlanner
      : defaults.delayedStartInMinutes
      ? roundTimeToChunk(addMinutes(new Date(), defaults.delayedStartInMinutes || 0))
      : null;

  return {
    ...defaultTask,
    timeChunksRequired: defaults.timeChunksRequired || 2,
    snoozeUntil: snoozeUntil,
    due: addDays(setMinutes(setHours(snoozeUntil || new Date(), 20), 0), defaults.dueInDays || 3),
    eventCategory: defaults.category.key === EventType.Work.key ? PrimaryCategory.SoloWork : PrimaryCategory.Personal,
    minChunkSize: defaults.minChunkSize || 1,
    maxChunkSize: defaults.maxChunkSize || 4,
    alwaysPrivate: defaults.alwaysPrivate,
  } as Task;
}
