import { concat, filter, fromPromise, map, pipe, Source } from "wonka";
import { Override } from "../types";
import { strToDate } from "../utils/dates";
import { deserialize, upsert } from "../utils/wonka";
import { SubscriptionType } from "./adaptors/ws";
import {
  DailyHabit as DailyHabitDto,
  ExpandedPlan as ExpandedPlanDto,
  HabitPlanItem as HabitPlanItemDto,
  OneOnOnePlanItem as OneOnOnePlanItemDto,
  PlanItem as PlanItemDto,
  Prioritizable as PrioritizableDto,
  PrioritizableActions as PrioritizableActionsDto,
  RecurringOneOnOne as RecurringOneOnOneDto,
  Task as TaskDto,
  TaskPlanItem as TaskPlanItemDto,
} from "./client";
import { dtoToHabit, Habit } from "./Habits";
import { dtoToOneOnOne, OneOnOne } from "./OneOnOnes";
import { Smurf } from "./Projects";
import { dtoToTask, Task } from "./Tasks";
import { TransformDomain } from "./types";

export enum PlanItemType {
  Task = "TASK",
  Habit = "HABIT",
  OneOnOne = "ONE_ON_ONE",
}

export enum PlanItemStatus {
  Scheduled = "SCHEDULED",
  Unscheduled = "UNSCHEDULED",
  Completed = "COMPLETED",
}

export type Prioritizable = Override<PrioritizableDto, {
  priority?: Smurf;
  priorityUntil?: string;
}>;

export type PrioritizableActions = PrioritizableActionsDto;

export type TaskActions = PrioritizableActions & {};

export type HabitActions = PrioritizableActions & {};

export type OneOnOneActions = PrioritizableActions & {};

export interface PlanItem
  extends Override<
    PlanItemDto,
    {
      readonly actions?: PrioritizableActions;

      type: PlanItemType;
      data: Task | Habit | OneOnOne;
      status: PlanItemStatus;
      priority?: Smurf;
    }
  > {}

export type TaskPlanItem = Override<
  TaskPlanItemDto,
  {
    readonly type: PlanItemType.Task;
    readonly actions?: TaskActions;
    readonly status?: PlanItemStatus;
    readonly priority?: Smurf;

    timeChunksRequired: number;
    timeChunksSpent: number;
    timeChunksRemaining: number;
    percentComplete: number;
    overdue: boolean;
    atRisk: boolean;
  }
> &
  Override<
    PlanItem,
    {
      type: PlanItemType.Task;
      data: Task;
    }
  >;

export type HabitPlanItem = Override<
  HabitPlanItemDto,
  {
    readonly type: PlanItemType.Habit;
    readonly actions?: HabitActions;
    readonly status?: PlanItemStatus;
    readonly priority?: Smurf;

    maxPossible: number;
    completed: number;
    scheduled: number;
  }
> &
  Override<
    PlanItem,
    {
      type: PlanItemType.Habit;
      data: Habit;
    }
  >;

export type OneOnOnePlanItem = Override<
  OneOnOnePlanItemDto,
  {
    readonly type: PlanItemType.OneOnOne;
    readonly actions?: OneOnOneActions;
    readonly status?: PlanItemStatus;
    readonly priority?: Smurf;

    doneDate?: Date;
  }
> &
  Override<
    PlanItem,
    {
      type: PlanItemType.OneOnOne;
      data: OneOnOne;
    }
  >;

export type ExpandedPlan = Override<
  ExpandedPlanDto,
  {
    oneOnOnes?: OneOnOnePlanItem[];
    scheduled: (TaskPlanItem | HabitPlanItem)[];
    atRisk: (TaskPlanItem | HabitPlanItem)[];
    recentTasks: (TaskPlanItem | HabitPlanItem)[];
    done: (TaskPlanItem | HabitPlanItem)[];
  }
>;

/** PLAN ITEMS DOMAIN */

export function dtoToPlanItem<R extends PlanItem>(dto: PlanItemDto): R {
  const data = {
    ...dto,
  } as unknown as R;

  if (!!dto.data) {
    if ("ONE_ON_ONE" === dto.type) data.data = dtoToOneOnOne(dto.data as RecurringOneOnOneDto);
    else if ("TASK" === dto.type) data.data = dtoToTask(dto.data as TaskDto);
    else if ("HABIT" === dto.type) data.data = dtoToHabit(dto.data as DailyHabitDto);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    else data.data = dto.data as any;
  }

  if (!!dto["doneDate"]) {
    data["doneDate"] = strToDate(dto["doneDate"]);
  }

  return data;
}

export function dtoToTypedPlanItem(dto: PlanItemDto) {
  if ("ONE_ON_ONE" === dto.type) return dtoToPlanItem<OneOnOnePlanItem>(dto);
  if ("TASK" === dto.type) return dtoToPlanItem<TaskPlanItem>(dto);
  // else if ("HABIT" === dto.type) return dtoToPlanItem<HabitPlanItem>(dto);
  else return dtoToPlanItem<HabitPlanItem>(dto);
}

const AssistPlannedSubscription = {
  subscriptionType: SubscriptionType.AssistCompleted,
};

export class PlanItemsDomain extends TransformDomain<PlanItem, PlanItemDto> {
  resource = "PlanItem";
  cacheKey = "planItems";
  pk = ["type", "data.id"];

  public deserialize = dtoToTypedPlanItem;

  watchAll$ = pipe(
    this.ws.subscription$$(AssistPlannedSubscription),
    filter((envelope) => !!envelope.data?.expandedPlan),
    map((envelope) => envelope.data.expandedPlan),
    map((expandedPlan: ExpandedPlanDto) => Object.values(expandedPlan).flat()),
    deserialize(this.deserialize),
    map((items) => this.patchExpectedChanges(items))
  );

  list$$ = (q: string | undefined, start: Date, end: Date, limit?: number) => {
    return pipe(
      fromPromise(this.search(q, start, end, limit)),
      map((items) => this.patchExpectedChanges(items))
    );
  };

  listAndWatch$$ = (q: string | undefined, start: Date, end: Date, limit?: number) => {
    return pipe(
      concat<PlanItem[] | null>([this.list$$(q, start, end, limit), this.watchAll$]),
      upsert((e) => this.getPk(e)),
      map((items) => [...items])
    );
  };

  search = this.manageErrors(
    this.deserializeResponse((q: string | undefined = "", start: Date, end: Date, limit?: number) => {
      return this.api.schedule.query2({ q, start: start.toISOString(), end: end.toISOString(), limit });
    })
  );
}

/** EXPANDED PLAN DOMAIN */

export function dtoToExpandedPlan(dto: ExpandedPlanDto): ExpandedPlan {
  return {
    ...dto,
    oneOnOnes: dto.oneOnOnes?.map((dto) => dtoToPlanItem<OneOnOnePlanItem>(dto)) || [],
    scheduled: (dto.scheduled?.map(dtoToTypedPlanItem) as unknown as (TaskPlanItem | HabitPlanItem)[]) || [],
    atRisk: (dto.atRisk?.map(dtoToTypedPlanItem) as unknown as (TaskPlanItem | HabitPlanItem)[]) || [],
    recentTasks: (dto.recentTasks?.map(dtoToTypedPlanItem) as unknown as TaskPlanItem[]) || [],
    done: (dto.done?.map(dtoToTypedPlanItem) as unknown as (TaskPlanItem | HabitPlanItem)[]) || [],
  };
}

// TODO (IW): Implement properly when needed
export function expandedPlanToDto(plan: Partial<ExpandedPlan>): Partial<ExpandedPlanDto> {
  return plan as Partial<ExpandedPlanDto>;
}

export class ExpandedPlanDomain extends TransformDomain<ExpandedPlan, ExpandedPlanDto> {
  resource = "ExpandedPlan";
  cacheKey = "expandedPlans";
  pk = "id"; // FIXME (IW): No actual PK (perhaps add start/end to payload)

  public deserialize = dtoToExpandedPlan;
  public serialize = expandedPlanToDto;

  watchAll$ = pipe(
    this.ws.subscription$$(AssistPlannedSubscription),
    filter((envelope) => !!envelope.data?.expandedPlan),
    map((envelope) => envelope.data.expandedPlan),
    deserialize(this.deserialize),
    map((items) => this.patchExpectedChanges(items)),
    // Schedule is only one item
    map((items) => items[0])
  );

  watch$$ = (start: Date, end: Date) => {
    const subscription = {
      subscriptionType: SubscriptionType.AssistPlanned,
      startTime: start,
      endTime: end,
    };

    return pipe(
      this.ws.subscription$$(subscription),
      filter((envelope) => !!envelope.data?.expandedPlan),
      map((envelope) => envelope.data.expandedPlan),
      deserialize(this.deserialize),
      map((items) => this.patchExpectedChanges(items)),
      // Schedule is only one item
      map((items) => items[0])
    );
  };

  list$$ = (start: Date, end: Date): Source<ExpandedPlan> =>
    pipe(
      fromPromise(this.get(start, end)),
      map((items) => this.patchExpectedChanges(items))
    );

  listAndWatch$$ = (start: Date, end: Date): Source<ExpandedPlan> => {
    return concat([this.list$$(start, end), this.watch$$(start, end)]);
  };

  get = this.manageErrors(
    this.deserializeResponse((start: Date, end: Date) => {
      return this.api.schedule.expandedPlan({
        start: start.toISOString(),
        end: end.toISOString(),
      });
    })
  );
}
